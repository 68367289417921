import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { FaEye } from "react-icons/fa";
import moment from "moment";

function Leads() {
  const [leadsDetails, setLeadsDetails] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [selectedStage, setSelectedStage] = useState("");

  // Fetch Lead Details from API
  const fetchLeadDetails = async () => {
    const token = sessionStorage.getItem("Token");
    if (!token) {
      setError("No authentication token found!");
      setLoading(false);
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("authtoken", token);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://api.theleadsense.com/api/v1//internal/lead/details",
        requestOptions
      );
      const result = await response.json();

      if (result?.data?.leads) {
        setLeadsDetails(result.data.leads);
        setFilteredLeads(result.data.leads);
      } else {
        setError("No leads data found in API response.");
      }
    } catch (err) {
      setError("Error fetching leads data. Please try again.");
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeadDetails();
  }, []);

  // Handle Search Input
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearch(value);
    setFilteredLeads(
      leadsDetails.filter(
        (lead) =>
          lead.name?.toLowerCase().includes(value) ||
          lead.email?.toLowerCase().includes(value) ||
          lead.phone?.toLowerCase().includes(value) ||
          lead.course?.toLowerCase().includes(value)
      )
    );
  };

  // Handle Stage Filtering
  const handleStageFilter = (e) => {
    const value = e.target.value;
    setSelectedStage(value);

    if (value === "") {
      setFilteredLeads(leadsDetails); // Reset filter
    } else {
      setFilteredLeads(
        leadsDetails.filter((lead) => String(lead.stage) === value)
      );
    }
  };

  // Define Table Columns
  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Lead ID",
      selector: (row) => row.id || "N/A",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name || "N/A",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email || "N/A",
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone || "N/A",
      sortable: true,
    },
    {
      name: "Course",
      selector: (row) => row.course || "N/A",
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (row.status === "0" ? "Inactive" : "Active"),
      sortable: true,
    },
    {
      name: "Stage",
      selector: (row) => {
        switch (row.stage) {
          case "0":
            return "Open Lead";
          case "1":
            return "RNR";
          case "2":
            return "Progress";
          case "3":
            return "Rejected";
          case "4":
            return "Good Lead";
          case "5":
            return "Prospect";
          default:
            return "N/A";
        }
      },
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) =>
        row.cdate
          ? moment(row.cdate, "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY")
          : "N/A",
      sortable: true,
    },
  ];

  return (
    <div className="reports">
      <div className="row">
        <div className="col-lg-12">
          <h3>Leads Details</h3>
        </div>
        <div className="col-lg-6 mb-3">
          <input
            type="text"
            value={search}
            onChange={handleSearch}
            placeholder="Search by name, email, or phone"
            className="form-control mb-2"
          />
        </div>
        <div className="col-lg-6 mb-3">
          <select
            value={selectedStage}
            onChange={handleStageFilter}
            className="form-control"
          >
            <option value="">All Stages</option>
            <option value="0">Open Lead</option>
            <option value="1">RNR</option>
            <option value="2">Progress</option>
            <option value="3">Prospect</option>
            <option value="4">Good Lead</option>
          </select>
        </div>
        <div className="col-lg-12">
          {loading ? (
            <div>Loading leads...</div>
          ) : error ? (
            <div className="text-danger">{error}</div>
          ) : (
            <DataTable
              columns={columns}
              data={filteredLeads}
              pagination
              paginationPerPage={5} // Default rows per page
              paginationRowsPerPageOptions={[5, 10, 15, 20]} // Options for rows per page
              responsive
              highlightOnHover
              striped
              noDataComponent={<div>No leads available</div>}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Leads;
