import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login/Login';

import './App.css';
import DefaultLayout from './component/Default/Default';
import Popular_Courses from './pages/University/university_courses';

function App() {
  const [submitted, setSubmitted] = useState(() => {
    // Retrieve the submitted state from sessionStorage or default to false
    const savedState = sessionStorage.getItem('submitted');
    return savedState === 'true'; // Convert string back to boolean
  });

  // Persist the submitted state whenever it changes
  useEffect(() => {
    sessionStorage.setItem('submitted', submitted);
  }, [submitted]);

  return (
    <Router>
      <div className="App">
        <Routes>
          {submitted ? (
            <>
              <Route path="/*" element={<DefaultLayout />} />
              <Route path="/university-filter" element={<Popular_Courses />} />
            </>
          ) : (
            <Route
              path="/"
              element={<Login submitted={submitted} setSubmitted={setSubmitted} />}
            />
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
