import React, { use, useEffect, useState } from "react";
import "./Common.css";

import { useNavigate } from "react-router-dom";
import "./Linkcontainer.css"

import { BsFilter, BsSearch } from "react-icons/bs";
import axios from "axios";
import Header_explore from "./Header_explore";


function Popular_Courses() {

    const [universityName, setuniversityName] = useState();
    console.log('kijjjj', universityName)

    const [isScrolled, setIsScrolled] = useState(false);
    const [filter, setFilter] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const openModal = () => setIsModalOpen(true);
    const handleClickLargeScreen = (e, u) => {

        const uni = e + ' offers an ' + u
        console.log('kkkkkkkkkkkk', e)
        setuniversityName(e)
        openModal();
    };
    const functionUpdate = () => {
        setFilter(!filter)
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);



    const explorecourse = (e) => {
        window.open(e, '_blank');
    };

    const [universitiesd, setUniversities] = useState([]);
    useEffect(() => {
        const fetchUniversities = async () => {
            try {
                const response = await axios.get("https://api.collegeall.com/api/v1/topUniversity");
                if (response.data && response.data.data && response.data.data.top_university) {
                    setUniversities(response.data.data.top_university);
                }
            } catch (err) {
                setError("Failed to fetch universities");
            } finally {

            }
        };

        fetchUniversities();
    }, []);

    const [courses, setCourses] = useState([
        "Sort by",
        "Recommendation",
        "Fee - Low to High",
        "Fee - High to Low",
        "Ranking - High to Low",
    ]);
    const [uni_courses, setUni_couses] = useState([])

    const settings = {
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "block",
                    background: "#087FFF",
                    borderRadius: "100%",
                }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{
                    ...style,
                    display: "block",
                    background: "#087FFF",
                    borderRadius: "100%",
                }}
                onClick={onClick}
            />
        );
    }


    const [filteredinfodata, setFilterinfodata] = useState(true)

    const [error, setError] = useState(null);
    // const fetchData = async () => {
    //     let config = {
    //         method: "get",
    //         maxBodyLength: Infinity,
    //         url: "https://api.collegeall.com/api/v1/universityfilter",
    //         headers: {},
    //     };

    //     try {
    //         const response = await axios.request(config);
    //         setFiltered(response.data?.data?.filter);
    //         setFilteredUniversitie(response.data?.data?.filter.slice(0, visibleCount))
    //     } catch (err) {
    //         setError(err.message); // Store error message in state
    //     }
    // };

    // useEffect(() => {
    //     fetchData();
    // }, []);

    useEffect(() => {
        const fetchData_courses = async () => {
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: "https://api.collegeall.com/api/v1/universityfilter?search=ALL",
                headers: {},
            };

            try {
                const response = await axios.request(config);
                setFiltered(response.data?.data?.filter);
                setFilteredUniversitie(response.data?.data?.filter.slice(0, visibleCount))
                // setFilteredUniversitie(response.data?.data?.filter);
                setUni_couses(response.data?.data?.filter);
            } catch (err) {
                setError(err.message); // Store error message in state
            }
        };

        fetchData_courses();
    }, []);

    const [activeIndex, setActiveIndex] = useState(0);
    const [filtered, setFiltered] = useState([]);

    const HandleCourseFilter = (index, d) => {
        setActiveIndex(index);
        let sortedData = [...filteredUniversitie]; // Make a copy to avoid mutating state directly
        if (d === "Fee - Low to High") {
            sortedData.sort((a, b) => a.fee - b.fee);
        } else if (d === "Fee - High to Low") {
            sortedData.sort((a, b) => b.fee - a.fee);
        } else if (d === "Ranking - High to Low") {
            sortedData.sort((a, b) => b.rank - a.rank); // Fix sorting logic for rank
        } else {

        }

        setFilteredUniversitie(sortedData); // Update state with sorted array
    };
    const [searchTerm, setSearchTerm] = useState("");
    const [searchTerm2, setSearchTerm2] = useState("");

    // Filter courses based on search term
    const filteredCourses = uni_courses?.filter(course =>
        course.course_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const groupedCourses = filteredCourses?.reduce((acc, course) => {
        const existingGroup = acc.find(group => group.course_name === course.course_name);
        if (existingGroup) {
            existingGroup.courses.push(course);
        } else {
            acc.push({ course_name: course.course_name, courses: [course] });
        }
        return acc;
    }, []);
    const filteredUniversities = universitiesd?.filter(university =>
        university.University_name.toLowerCase().includes(searchTerm2.toLowerCase())
    );
    const [selectedCourse, setSelectedCourse] = useState('');
    const [selectedUniversity, setSelectedUniversity] = useState('');
    const [visibleCount, setVisibleCount] = useState(8); // Start with 8 universities
    const [filteredUniversitie, setFilteredUniversitie] = useState([]);
    console.log(filteredUniversitie)
    const [filteredinfo, setfilteredinfo] = useState([]);

    const ApplyFilter = () => {
        let filteredData = [...filtered];

        if (selectedUniversity && selectedCourse) {
            filteredData = filteredData.filter((university) =>
                university?.University_name === selectedUniversity &&
                university?.course_name === selectedCourse
            );
        } else if (selectedUniversity) {
            filteredData = filteredData.filter((university) =>
                university?.University_name === selectedUniversity
            );
        } else if (selectedCourse) {
            filteredData = filteredData.filter((university) =>
                university?.course_name === selectedCourse
            );
        }
        console.log(filteredData)

        setFilteredUniversitie(filteredData.slice(0, visibleCount));
        setFilter(true)
        setFilterinfodata(false)
        setfilteredinfo(filteredData)

    };
    const loadMore = () => {
        console.log(filteredinfodata)
        if (filteredinfodata == true) {

            setVisibleCount(prevCount => prevCount + 8);
            setFilteredUniversitie(filtered.slice(0, visibleCount + 8));
        } else {
            console.log('kkkkkkkkkkkkkk')
            setVisibleCount(prevCount => prevCount + 8);
            setFilteredUniversitie(filteredinfo.slice(0, visibleCount + 8));
            console.log(filteredinfo)
        }

    };

    return (
        <div className="course">

            <div className="course-slider">
                <div className="slider-container mt-4 align-items-center">
                    <div className="position-relative w-100">
                        <input type="text" className="form-control pl-4" style={{ width: "100%" }} placeholder="Search..." />
                        <BsSearch className="position-absolute" style={{ top: "50%", right: "10px", transform: "translateY(-50%)" }} />
                    </div>
                    <button className="btn btn-primary d-flex align-items-center" onClick={functionUpdate} style={{ marginLeft: "10px" }}>
                        <BsFilter style={{ marginRight: "5px" }} />
                        Filter
                    </button>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    <div className="courselist">
                        {courses?.map((filter, index) => (
                            <button
                                key={index}
                                className={index === activeIndex ? "active" : ""}
                                onClick={() => HandleCourseFilter(index, filter)}
                            >
                                {filter}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="degreelist mt-3">
                        <div className="coursenames mt-5">
                            <h5>Course</h5>
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                            <div className="course_listitems">
                                {groupedCourses.map((course, index) => (
                                    <label key={index}>
                                        <input
                                            type="radio"
                                            name="coursenames"
                                            value={course.course_name}
                                            checked={selectedCourse == course?.course_name}
                                            onClick={() => setSelectedCourse(course?.course_name)}
                                        />
                                        {course.course_name}
                                    </label>
                                ))}
                            </div>
                        </div>

                        <div className="coursenames">
                            <h5>University's</h5>
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchTerm2}
                                onChange={(e) => setSearchTerm2(e.target.value)}
                            />
                            <div className="course_listitems">
                                {filteredUniversities.map((university, index) => (
                                    <label key={index}>
                                        <input
                                            type="radio"
                                            name="universitynames"
                                            value={university.University_name}
                                            checked={selectedUniversity == university?.University_name}
                                            onChange={() => setSelectedUniversity(university?.University_name)}
                                        />
                                        {university.University_name}
                                    </label>
                                ))}
                            </div>
                        </div>
                        <div>
                            <button className="btn btn-primary w-100 mt-5" onClick={ApplyFilter}>Apply Now</button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-9">
                    <div className="university mt-3">
                        {filteredUniversitie?.length > 0 ? (
                            <div className="row">
                                {filteredUniversitie.map((data, index) => (
                                    <div className="col-lg-3 col-6" key={index}>
                                        <div className="card mt-3">
                                            <div className="university-content" onClick={() => handleClickLargeScreen(data, data?.course_name)}>
                                                <img src={data.university_img} alt={data.University_name} />
                                                <h5 style={{ backgroundColor: "#004aad", color: "white", padding: "10px", overflowY: "hidden", textWrap: "nowrap" }}>
                                                    {data.course_name}
                                                </h5>
                                                <h6>{data.University_name}</h6>
                                                <h6>{data.noofyear}</h6>
                                                <h6>{data.last_date_ap}</h6>
                                                <h5 style={{ color: "#087FFF" }}>★{data?.rank} Rank ₹{data?.fee}</h5>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div style={{ textAlign: "center", marginTop: "1rem" }}>
                                <h4 style={{ color: "#888", fontWeight: "bold" }}>No data to display</h4>
                            </div>
                        )}

                        {filteredUniversitie?.length > 0 && visibleCount < filtered.length && (
                            <div className="text-center mt-3">
                                <button onClick={loadMore} className="btn btn-primary">View More</button>
                            </div>
                        )}
                    </div>

                </div>
            </div>

            <Header_explore
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                universityName={universityName}

            />
            <div className="degreelist_mob" style={{ display: filter ? "none" : "block" }} >

                <div className="coursenames mt-5">
                    <h5>Course</h5>
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="course_listitems">
                        {filteredCourses.map((course, index) => (
                            <label key={index}>
                                <input
                                    type="radio"
                                    name="coursename"
                                    value={course.course_name}
                                    checked={selectedCourse == course?.course_name}
                                    onClick={() => setSelectedCourse(course?.course_name)}
                                />
                                {course.course_name}
                            </label>
                        ))}
                    </div>
                </div>

                <div className="coursenames">
                    <h5>University's</h5>
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm2}
                        onChange={(e) => setSearchTerm2(e.target.value)}
                    />
                    <div className="course_listitems">
                        {filteredUniversities.map((university, index) => (
                            <label key={index}>
                                <input
                                    type="radio"
                                    name="universityname"
                                    value={university.University_name}
                                    checked={selectedUniversity == university?.University_name}
                                    onClick={() => setSelectedUniversity(university?.University_name)}
                                />
                                {university.University_name}
                            </label>
                        ))}
                    </div>
                </div>
                <div>
                    <button className="btn btn-primary w-100 mt-5" onClick={ApplyFilter}>Apply Now</button>
                </div>
            </div>
        </div >
    );
}

export default Popular_Courses;
