import React from 'react'
import { useState } from 'react';
import { useParams } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import DataTable from "react-data-table-component";

function EmpDetails() {
  const [search, setSearch] = useState("");
    const { employee } = useParams();
    
    const Ram=[
        {
            Name:"Ram Kumar",
            Role:"Developer",
            JoinDate:"08/05/2024",
            Email:"ram@gmail.com",
            Aadhar:"4572 8641 8461",
            FatherName:"RRRR",
            Fatherjob:"RRRR",
        }
    ]
    const Karthik=[
        {
            Name:"Karthik",
            Role:"Digital Marketing",
            JoinDate:"10/02/2017",
            Email:"karthik@gmail.com",
            Aadhar:"3875 7563 8483",
            FatherName:"SSSS",
            Fatherjob:"SSSS"
        }
    ]

    const Veronica=[
      {
        Name:"Veronica",
        Role:"HR",
        JoinDate:"05/01/2025",
        Email:"veronica@gmail.com",
        Aadhar:"2564 5464 8943",
        FatherName:"VVVV",
        Fatherjob:"VVVV"
      }
    ]

    const allData=[
        ...Ram,
        ...Karthik,
        ...Veronica
    ]

    const filteredData=allData.filter((row)=>row.Name === employee)

    const columns=[
        {
            name:"S.No",
            selector:(row,index)=>index+1,
            sortable:true,
        },
        {
            name:"Name",
            selector:(row)=>row.Name,
            sortable:true,
        },
        {
            name:"Role",
            selector:(row)=>row.Role,
            sortable:true,
        },
        {
            name:"Join Date",
            selector:(row)=>row.JoinDate,
            sortable:true,
        },
        {
            name:"Email",
            selector:(row)=>row.Email,
            sortable:true,
        },
        {
            name:"Aadhar No",
            selector:(row)=>row.Aadhar,
            sortable:true,
        },
        {
            name:"Father's Name",
            selector:(row)=>row.FatherName,
            sortable:true,
        },
        {
            name:"Father's Job",
            selector:(row)=>row.Fatherjob,
            sortable:true,
        },
        {
                name:"Edit",
                selector:(row)=><FaEdit />,
                sortable:true,
            },
            {
                name:"Delete",
                selector:(row)=><MdDelete style={{fontSize:"20px",color:'red',cursor:"pointer"}}/>,
                sortable:true,
            },
    ]
  return (
    <div className="department">
    <div className="row">
      <div className="col-lg-12 mb-3 mt-3">
        <h3>{employee} Details</h3>
      </div>

      <div className="col-lg-12">
        <DataTable
          columns={columns}
          data={filteredData.filter((row) => {
            if (!search) return true;
            return row.EmpName.toLowerCase().includes(search.toLowerCase());
          })}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
          responsive
          highlightOnHover
          striped
          noDataComponent={<div>No data available</div>}
        />
      </div>
    </div>
  </div>
  )
}

export default EmpDetails
