import React, { useState } from 'react'
import Assetss from '../../Assets/Assetss'

function Universities() {
    const[universities,setUniversities]=useState([
        {
          Uni_img:Assetss?.Andhra_University,
          Uni_name:"Andhra University",
          Uni_rank:"Rank:⭐⭐⭐⭐⭐"
        },
        {
            Uni_img:Assetss?.Bharathidasan_University,
            Uni_name:"Bharathidasan University",
            Uni_rank:"Rank:⭐⭐⭐⭐⭐",
        },
        {
        Uni_img:Assetss?.Centurion_University,
        Uni_name:"Centurion University",
        Uni_rank:"Rank:⭐⭐⭐⭐⭐",
        },
        {
        Uni_img:Assetss?.Chandigarh_University,
        Uni_name:"Chandigarh University",
        Uni_rank:"Rank:⭐⭐⭐⭐⭐",
        },
        {
         Uni_img:Assetss?.D_Y_PATIL_University_mumbai,
         Uni_name:"DY Patil University Mumbai",
         Uni_rank:"Rank:⭐⭐⭐⭐⭐",
        },
        {
            Uni_img:Assetss?.DY_Patil_University,
            Uni_name:"DY Patil University",
            Uni_rank:"Rank:⭐⭐⭐⭐⭐",
        },
        {
          Uni_img:Assetss?.Dr_MGR_University,
          Uni_name:"Dr. MGR University",
          Uni_rank:"Rank:⭐⭐⭐⭐⭐",
        },
        {
            Uni_img:Assetss?.Jain_University,
            Uni_name:"Jain University",
            Uni_rank:"Rank:⭐⭐⭐⭐⭐",
        },
        {
            Uni_img:Assetss?.KIIT_University,
            Uni_name:"KIIT University",
            Uni_rank:"Rank:⭐⭐⭐⭐⭐",
        },
        {
            Uni_img:Assetss?.KL_University,
            Uni_name:"KL University",
            Uni_rank:"Rank:⭐⭐⭐⭐⭐",
        },
        {
           Uni_img:Assetss?.Kurukshetra_University,
           Uni_name:"Kurukshetra University",
           Uni_rank:"Rank:⭐⭐⭐⭐⭐",
        },
        {
            Uni_img:Assetss?.manipal_university_jaipur,
            Uni_name:"Manipal University Jaipur",
            Uni_rank:"Rank:⭐⭐⭐⭐⭐",
        },
        {
            Uni_img:Assetss?.Noida_International_University,
            Uni_name:"Noida International University",
             Uni_rank:"Rank:⭐⭐⭐⭐⭐",
        },
        {
            Uni_img:Assetss?.OP_Jindal_Global_University,
            Uni_name:"O.P Jindal Global University",
            Uni_rank:"Rank:⭐⭐⭐⭐⭐",
        },
        {
            Uni_img:Assetss?.manipal_smu,
            Uni_name:"Sikkim Manipal University",
            Uni_rank:"Rank:⭐⭐⭐⭐⭐",
        },
        {
            Uni_img:Assetss?.Vignan_University,
            Uni_name:"Vignan University",
            Uni_rank:"Rank:⭐⭐⭐⭐⭐",
        },
        {
            Uni_img:Assetss?.Vivekananda_Global_University,
            Uni_name:"Vivekananda Global University",
            Uni_rank:"Rank:⭐⭐⭐⭐⭐",
        },
    ])
  return (
    <div className='container row'>
        <div className='text-center'>
            <h2>All Universities</h2>
        </div>
        {universities?.map((item,index)=>(
            <div className='col-lg-3 mt-4'>
                <div className='card' style={{height:"245px",boxShadow:"rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",cursor:"pointer"}}>
                     <img src={item.Uni_img} alt='University Image' style={{height:"160px"}}/>
                     <h6 className='text-center'>{item.Uni_name}</h6>
                     <h6 className='text-center' style={{fontSize:"12px"}}>{item.Uni_rank}</h6>
                     <button style={{backgroundColor:"#343a40",color:"white",width:"50px",height:"25px",display:"flex",alignItems:"center",justifyContent:"center",marginLeft:"75%"}}>View</button>
                 </div>
            </div>
        ))}
      
    </div>
  )
}

export default Universities
