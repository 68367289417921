import React from 'react';
import './Sidebar.css';

function Sidebar({ menuItems, logo }) {
    return (
        <aside className="sidebar">
            <div className="sidebar-logo">
                <img src={logo} alt="Logo" className="sidebar-logo-img" />
            </div>
            <nav className="sidebar-nav">
                <ul>
                    {menuItems.map((item, index) => (
                        <li key={index} className="sidebar-item">
                            <a href={item.link} className="sidebar-link">
                                {item.icon && <span className="sidebar-icon">{item.icon}</span>}
                                <span className="sidebar-text">{item.name}</span>
                                {item.dropdownIcon && <span className="sidebar-dropdown-icon" style={{fontSize:"20px"}}>{item.dropdownIcon}</span>}
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
        </aside>
    );
}

export default Sidebar;
