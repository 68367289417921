import React from 'react';
import Assets from '../../Assets/Assets';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import Appcontent from './Appcontent';
import { FaCaretDown } from "react-icons/fa";



function DefaultLayout() {
    const menuItems = [
        { name: 'Dashboard', link: '/', icon: '📊' },  // Updated icon to Bar Chart
        { name: 'Lead Master', link: '/leads', icon: '📘' },  // Updated icon to Book
        { name: 'Reports', link: '/reports', icon: '📑' },  // Added Reports with Page and Bar Chart icon
        { name: 'Internal', link: '/internal', icon: '📈' },  // Updated icon to Line ChartF
        // { name: 'University', link: '/university-filter', icon: '💼' },  // Updated link and added briefcase icon
        { name: 'University', link: '/university', icon: '💼' },
        { name: 'Register Student', link: '/jobregister', icon: '🔒' },
       { name: 'Departments',link: '/departments',icon: '🏢',dropdownIcon: <FaCaretDown />},// Updated link and added office building icon
        { name: 'Employee', link: '/employee', icon: '👤' },  // Updated link and added user icon
        { name: 'Register Student', link: '/register-student', icon: '🎓' },  // Updated link and added graduation cap icon
        { name: 'Accounts', link: '/accounts', icon: '💰' },  // Updated link and added money bag icon
        { name: 'Assets', link: '/assets', icon: '🛒' },  // Updated link and added shopping cart icon
        { name: 'Logout', link: '/logout', icon: '🔒' },  // Updated icon to Locked Padlock
    ]
    return (
        <div className="dashboard-layout">

            <Sidebar menuItems={menuItems} logo={Assets?.Logo} />
            <div className="main-content">
                <Header
                    logo={Assets?.Logo}
                    userName="John Doe"
                    profilePhoto={Assets?.Login_b}
                />
                <div className="contents" style={{ textAlign: "start", paddingLeft: "19%", paddingTop: "6%" }}>
                    <Appcontent />
                </div>
            </div>
        </div>
    );
}

export default DefaultLayout;
