import React from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { MdOutlineAdd } from "react-icons/md";

function ResgisterStudents() {
  const [selectedStage, setSelectedStage] = useState("");
  const student = [
    {
      stuName: "SSSS",
      stuMail: "sss@gmail.com",
      stuPhone: "9127898389",
      stuCourse: "PGDM",
      stuStatus: "Active",
      date: "08/02/2025",
    },
  ];

  const allData = student.map((data, index) => ({
    StudentName: data.stuName,
    StudentMail: data.stuMail,
    StudentPhone: data.stuPhone,
    StudentCourse: data.stuCourse,
    StudentStatus: data.stuStatus,
    date: data.date,
    edit: <FaEdit />,
    delete: (
      <MdDelete style={{ fontSize: "20px", color: "red", cursor: "pointer" }} />
    ),
    stage: index,
  }));

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.StudentName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.StudentMail,
      sortable: true,
    },
    {
      name: "Phone No",
      selector: (row) => row.StudentPhone,
      sortable: true,
    },
    {
      name: "Course Name",
      selector: (row) => row.StudentCourse,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.StudentStatus,
      sortable: true,
    },
  ];
  return (
    <div className="department">
      <div className="row">
        <div className="col-lg-10 mb-3 mt-3">
          <h3>Register Students Details</h3>
        </div>
        <div className="col-lg-2 mt-3">
          <button
            style={{
              backgroundColor: "green",
              color: "white",
              borderRadius: "10px",
            }}
          >
            Add
            <MdOutlineAdd style={{ fontSize: "15px" }} />
          </button>
        </div>

        <div className="col-lg-12">
          <DataTable
            columns={columns}
            data={allData.filter((row) => {
              if (selectedStage === "") return true;
              return row.stage.toString() === selectedStage;
            })}
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            responsive
            highlightOnHover
            striped
            noDataComponent={<div>No data available</div>}
          />
        </div>
      </div>
    </div>
  );
}

export default ResgisterStudents;
