import React, { useState,useEffect } from 'react'
import axiosInstance from "../../component/API/axiosInstance"

function Centurion_University() {
    const [courses, setCourses] = useState([]);


    const ApiCourseData = (e) => {
        let config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `/universityfilter?university_name=${e}`,
          headers: {},
        };
    
        axiosInstance
          .request(config)
          .then((response) => {
            // console.log()
            setCourses(response?.data?.data?.filter);
          })
          .catch((error) => {
            console.log(error);
          });
      };
      useEffect(() => {
        ApiCourseData("Centurion University");
      }, []);
    
  return (
    <div className="row">
    <h2 className="text-center mt-2 mb-3">Centurion University</h2>
      {courses.map((item, index) => (
        <div className="col-lg-3">
          <div
            className="card"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              margin: "10px",
              height: "170px",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              backgroundColor: "white",
            }}
          >
            <h5 style={{ color: "#ff3333" }}>{item?.course_name}</h5>
            <h5>Duration:{item?.noofyear}</h5>
            <h6 style={{ color: "#3366ff" }}>Fees:{item?.fee}</h6>
            <button
              style={{
                backgroundColor: "#343a40",
                color: "white",
                width: "50px",
                height: "25px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "75%",
              }}
            >
              View
            </button>
          </div>
        </div>
      ))}
  </div>
  )
}

export default Centurion_University
