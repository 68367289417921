import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { GrView } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { MdOutlineAdd } from "react-icons/md";

function Department() {
  const [selectedStage, setSelectedStage] = useState("");
  const navigate = useNavigate();

  const departmentName = [
    {
      DepName: "HR",
    },
    {
      DepName: "Digital Marketing",
    },
    {
      DepName: "Developer",
    },
    {
      DepName: "UIUX Designer",
    },
    {
      DepName: "Data Analyst",
    },
    {
      DepName: "Sales",
    },
  ];

  const departmentAdmin = [
    {
      DepAdmin: "Veronica",
    },
    {
      DepAdmin: "Karthik",
    },
    {
      DepAdmin: "Ram Kumar",
    },
    {
      DepAdmin: "Bala",
    },
    {
      DepAdmin: "Logesh",
    },
    {
      DepAdmin: "Karthik",
    },
    {
      DepAdmin: "Karthik",
    },
  ];

  const depContactNo = [
    {
      DepContact: "6262987481",
    },
    {
      DepContact: "6262987481",
    },
    {
      DepContact: "6262987481",
    },
    {
      DepContact: "6262987481",
    },
    {
      DepContact: "6262987481",
    },
    {
      DepContact: "6262987481",
    },
    {
      DepContact: "6262987481",
    },
  ];

  const allData = departmentName.map((data, index) => ({
    departmentName: data.DepName,
    adminName: departmentAdmin[index]?.DepAdmin || "N/A",
    contactNo: depContactNo[index]?.DepContact || "N/A",
    view: (
      <GrView
        onClick={() => handleViewClick(data.DepName)}
        style={{ cursor: "pointer" }}
      />
    ),
    stage: index,
  }));

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Department Name",
      selector: (row) => row.departmentName,
      sortable: true,
    },
    {
      name: "Admin Name",
      selector: (row) => row.adminName,
      sortable: true,
    },
    {
      name: "Contact NO",
      selector: (row) => row.contactNo,
      sortable: true,
    },
    {
      name: "View",
      selector: (row) => row.view,
      sortable: true,
    },
  ];

  const handleViewClick = (department) => {
    navigate(`/depdetails/${department}`);
  };

  return (
    <div className="department">
      <div className="row">
        <div className="col-lg-9 mb-3 mt-3">
          <h3>Department Details</h3>
        </div>
        <div className="col-lg-1 mt-3">
          <h6
            style={{
              backgroundColor: "green",
              color: "white",
              borderRadius: "10px",
              width: "70px",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap:"2px",
              cursor:"pointer",
            }}
          >
            Add
            <MdOutlineAdd style={{ fontSize: "15px" }} />
          </h6>
        </div>

        <div className="col-lg-1 mt-3">
          <h6
            style={{
              backgroundColor: "darkblue",
              color: "white",
              borderRadius: "10px",
              width: "70px",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap:"4px",
              cursor:"pointer",
            }}
          >
            Edit
            <FaEdit style={{ fontSize: "18px" }}/>
          </h6>
        </div>
        <div className="col-lg-1 mt-3">
          <h6
            style={{
              backgroundColor: "red",
              color: "white",
              borderRadius: "10px",
              width: "80px",
              height: "45px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap:"2px",
              cursor:"pointer",
            }}
          >
            Delete <MdDelete style={{ fontSize: "20px" }}/>
          </h6>
        </div>

        <div className="col-lg-12">
          <DataTable
            columns={columns}
            data={allData.filter((row) => {
              if (selectedStage === "") return true;
              return row.stage.toString() === selectedStage;
            })}
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            responsive
            highlightOnHover
            striped
            noDataComponent={<div>No data available</div>}
          />
        </div>
      </div>
    </div>
  );
}

export default Department;
