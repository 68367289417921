import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import './Linkcontainer.css'
import axios from "axios";
function Header_explore({
    isModalOpen,
    setIsModalOpen,
    universityName
}) {
    const [values, setValues] = useState(universityName);

    useEffect(() => {
        if (universityName) {
            setValues(universityName);
        }
    }, [universityName]);
    const [errors, setErrors] = useState({
        course_name: "",
        University_name: "",
        noofyear: "",
        last_date_ap: "",
        rank: "",
        fee: "",
        id: "",

    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const Validation = () => {
        let formErrors = {};
        let isValid = false;

        if (!values.course_name) {
            formErrors.course_name = "course_name is required!";
            isValid = true;
        }
        if (!values.University_name) {
            formErrors.University_name = "University_name is required!";
            isValid = true;
        }
        if (!values.noofyear) {
            formErrors.noofyear = "noofyear is required!";
            isValid = true;
        }
        if (!values.last_date_ap) {
            formErrors.last_date_ap = "last_date_ap is required!";
            isValid = true;
        }
        if (!values.rank) {
            formErrors.rank = "rank is required!";
            isValid = true;
        }
        if (!values.fee) {
            formErrors.fee = "rank is required!";
            isValid = true;
        }


        setErrors(formErrors);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const validate = Validation();

        let data = JSON.stringify({
            "course_name": values?.course_name,
            "univeristy_name": values?.University_name,
            "course_year": values?.noofyear,
            "rank": values?.rank,
            "fee": values?.fee,
            "id": values?.id,
            "last_date": values?.last_date_ap
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://api.collegeall.com/api/v1/update-courses',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                console.log(response?.status)
                if (response?.status == 200) {
                    alert('University data is updated')
                    closeModal()
                } else{
                    alert('error on updating')
                    closeModal()
                }
            })
            .catch((error) => {
                console.log(error);
            });


    };


    const closeModal = () => setIsModalOpen(false);
    return (
        <div>
            {isModalOpen && (
                <div className="modalOverlay" onClick={closeModal}>
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className="modalContent"
                    >
                        <div>
                            <div className="col-lg-12">
                                <div className="form-container">
                                    <div className="row">
                                        <div className="col-lg-12 col-sm-12 col-12">
                                            <h4 className="text-center">
                                                Compare & Apply from 100+
                                            </h4>
                                        </div>
                                        <div className="col-lg-12 col-sm-12 col-12">
                                            <h6 className="text-center">
                                                for
                                                <span> Working Professionals Course</span>
                                            </h6>
                                        </div>

                                        <div className="col-lg-12 col-sm-12 col-12">
                                            <div className="input-form">
                                                <label>Univeristy Name</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="University_name"
                                                    value={values?.University_name}
                                                    onChange={(e) => handleChange(e)}
                                                    disabled
                                                />
                                                {errors.userName && (
                                                    <small style={{ color: "red" }}>
                                                        {errors.University_name}
                                                    </small>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-sm-6 col-12">
                                            <div className="input-form">
                                                <label>course Name</label>
                                                <input
                                                    className="form-control"
                                                    name="course_name"
                                                    type="text"
                                                    value={values?.course_name}
                                                    onChange={(e) => handleChange(e)}
                                                />

                                                {errors.age && (
                                                    <small style={{ color: "red" }}>
                                                        {errors.course_name}
                                                    </small>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-sm-6 col-6">
                                            <div className="input-form">
                                                <label htmlFor="exampleFormControlInput1">
                                                    Number of years
                                                </label>
                                                <input
                                                    type="text"
                                                    name="noofyear"
                                                    className="form-control"
                                                    value={values?.noofyear}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                                {errors.email && (
                                                    <small style={{ color: "red" }}>
                                                        {errors.noofyear}
                                                    </small>
                                                )}
                                            </div>
                                        </div>


                                        <div className="col-lg-6 col-sm-6 col-6 ">
                                            <div className="input-form">
                                                <label>Last Date of Application</label>
                                                <input

                                                    className="form-control"
                                                    style={{ width: "100%" }}
                                                    name="last_date_ap"
                                                    type="text"
                                                    value={values?.last_date_ap}
                                                    onChange={(e) => handleChange(e)}
                                                />

                                                {errors.dob && (
                                                    <small style={{ color: "red" }}>
                                                        {errors.last_date_ap}
                                                    </small>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-sm-6 col-6">
                                            <div className="input-form">
                                                <label>Rank</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="rank"
                                                    value={values?.rank}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                                {errors.state && (
                                                    <small style={{ color: "red" }}>
                                                        {errors.rank}
                                                    </small>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-sm-6 col-6">
                                            <div className="input-form">
                                                <label>fee</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="fee"
                                                    value={values?.fee}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                                {errors.country && (
                                                    <small style={{ color: "red" }}>
                                                        {errors.fee}
                                                    </small>
                                                )}
                                            </div>
                                        </div>


                                        <div className="col-lg-6 col-sm-12 col-6  mt-2">
                                            <div className="form_sumbit">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={handleSubmit}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12  col-6 mt-2">
                                            <div>
                                                <button
                                                    onClick={closeModal}
                                                    className="closeButton"
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Header_explore;
