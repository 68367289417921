import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import { MdOutlineAdd } from "react-icons/md";

import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

function DepDetails() {
  const { department } = useParams();
  const [search, setSearch] = useState("");

  const HR = [
    {
      DepName: "HR",
      EmpName: "Veronica",
      Exp: "6 Months",
    },
  ];
  const DigitalMarketing = [
    {
      DepName: "Digital Marketing",
      EmpName: "Karthik",
      Exp: "10 Years",
    },
    {
      DepName: "Digital Marketing",
      EmpName: "Uma",
      Exp: "1 year",
    },
  ];
  const Developer = [
    {
      DepName: "Developer",
      EmpName: "Ram Kumar",
      Exp: "3 Years",
    },
  ];
  const UIUXDesigner = [
    {
      DepName: "UIUX Designer",
      EmpName: "Bala",
      Exp: "1 Year",
    },
    {
      DepName: "UIUX Designer",
      EmpName: "Surenthar",
      Exp: "1 Year",
    },
  ];
  const Analyst = [
    {
      DepName: "Data Analyst",
      EmpName: "Logesh",
      Exp: "1 Year",
    },
  ];
  const BPO = [
    {
      DepName: "Sales",
      EmpName: "Durga",
      Exp: "1 Year",
    },
  ];
  const Interns = [
    {
      DepName: "HR",
      EmpName: "Rekha",
      Exp: "Intern",
    },
    {
      DepName: "Developer",
      EmpName: "Santhiya",
      Exp: "Intern",
    },
  ];

  const allData = [
    ...HR,
    ...DigitalMarketing,
    ...Developer,
    ...UIUXDesigner,
    ...Analyst,
    ...BPO,
    ...Interns,
  ];

  const filteredData = allData.filter((row) => row.DepName === department);

  const columns = [
    {
      name: "S.No",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Department Name",
      selector: (row) => row.DepName,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.EmpName,
      sortable: true,
    },
    {
      name: "Experience",
      selector: (row) => row.Exp,
    },
    {
      name: "Edit",
      selector: (row) => <FaEdit />,
      sortable: true,
    },
    {
      name: "Delete",
      selector: (row) => (
        <MdDelete
          style={{ fontSize: "20px", color: "red", cursor: "pointer" }}
        />
      ),
      sortable: true,
    },
  ];



  return (
    <div className="department">
      <div className="row">
        <div className="col-lg-10 mb-3 mt-3">
          <h3>{department} Department Details</h3>
        </div>
        <div className="col-lg-2 mt-3">
          <button
            style={{
              backgroundColor: "green",
              color: "white",
              borderRadius: "10px",
            }}
          >
            Add
            <MdOutlineAdd style={{ fontSize: "15px" }} />
          </button>
        </div>
        <div className="col-lg-12">
          <DataTable
            columns={columns}
            data={filteredData.filter((row) => {
              if (!search) return true;
              return row.EmpName.toLowerCase().includes(search.toLowerCase());
            })}
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
            responsive
            highlightOnHover
            striped
            noDataComponent={<div>No data available</div>}
          />
        </div>
      </div>
    </div>
  );
}

export default DepDetails;
