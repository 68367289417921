import Andhra_University from "../Assets/college background/Andhra_University.jpeg"
import Bharathidasan_University from "../Assets/college background/Bharathidasan_University.jpeg"
import Centurion_University  from "../Assets/college background/Centurion_University.jpeg"
import  Chandigarh_University from "../Assets/college background/Chandigarh_University.jpeg"
import D_Y_PATIL_University_mumbai from "../Assets/college background/D.Y PATIL University mumbai.jpeg"
import DY_Patil_University from "../Assets/college background/D.Y. Patil_University.jpeg"
import Dr_MGR_University from "../Assets/college background/Dr.MGR_University.jpeg"
import Jain_University from "../Assets/college background/Jain_University.jpeg"
import KIIT_University from "../Assets/college background/KIIT_University.jpeg"
import KL_University from "../Assets/college background/Koneru_Lakshmaiah_Education_Foundation_(KL University).jpeg"
import Kurukshetra_University from "../Assets/college background/Kurukshetra_University.png"
import manipal_university_jaipur from  "../Assets/college background/manipal university jaipur.jpg"
import Noida_International_University from "../Assets/college background/Noida_International_University.jpeg"
import OP_Jindal_Global_University from "../Assets/college background/OP_Jindal_Global_University.jpeg"
import manipal_smu from "../Assets/college background/manipal smu.jpg"
import Vignan_University from "../Assets/college background/Vignan_University.png"
import Vivekananda_Global_University from "../Assets/college background/Vivekananda_Global_University.jpeg"

const Assetss={
    Andhra_University:Andhra_University,
    Bharathidasan_University:Bharathidasan_University,
    Centurion_University:Centurion_University,
    Chandigarh_University: Chandigarh_University,
    D_Y_PATIL_University_mumbai:D_Y_PATIL_University_mumbai,
    DY_Patil_University:DY_Patil_University,
    Dr_MGR_University:Dr_MGR_University,
    Jain_University:Jain_University,
    KIIT_University:KIIT_University,
    KL_University:KL_University,
    Kurukshetra_University:Kurukshetra_University,
    manipal_university_jaipur:manipal_university_jaipur,
    Noida_International_University:Noida_International_University,
    OP_Jindal_Global_University:OP_Jindal_Global_University,
    manipal_smu:manipal_smu,
    Vignan_University:Vignan_University,
    Vivekananda_Global_University:Vivekananda_Global_University,
}
  


export default Assetss
