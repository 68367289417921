import React, { useState } from 'react'
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { MdOutlineAdd } from "react-icons/md";
import DataTable from "react-data-table-component";

function Assets() {
    const [selectedStage, setSelectedStage] = useState("");
    const productDetails=[
        {
            proName:"Laptop",
            proPrice:"60,000",
            date:"22/02/2025",
            proQty:"4",
            proWarrenty:"1 Year",
        },
        {
            proName:"Chair",
            proPrice:"40,000",
            date:"07/03/2025",
            proQty:"10",
            proWarrenty:"2 Years",
        },
    ]
    const allData=productDetails.map((data,index)=>({
        productName:data.proName,
        productPrice:data.proPrice,
        date:data.date,
        productQty:data.proQty,
        productWarrenty:data.proWarrenty,
          edit:<FaEdit />,
             delete:<MdDelete style={{fontSize:"20px",color:'red',cursor:"pointer"}}/>,
             stage: index,
    }))

    const columns=[
        {
            name:"S.No",
            selector:(row,index)=>index+1,
            sortable:true,
        },
        {
            name:"Product Name",
            selector:(row)=>row.productName,
            sortable:true,
        },
        {
            name:"Product Price",
            selector:(row)=>row.productPrice,
            sortable:true,
        },
        {
            name:"Date",
            selector:(row)=>row.date,
            sortable:true,
        },
        {
            name:"Qty",
            selector:(row)=>row.productQty,
            sortable:true,
        },
        {
            name:"Warrenty",
            selector:(row)=>row.productWarrenty,
            sortable:true,
        },
        {
            name:"Edit",
            selector:(row)=>row.edit,
            sortable:true,
        },
        {
            name:"Delete",
            selector:(row)=>row.delete,
            sortable:true,

        }
    ]
  return (
     <div className="department">
          <div className="row">
            <div className="col-lg-10 mb-3 mt-3">
              <h3>Assets Details</h3>
            </div>
            <div className="col-lg-2 mt-3">
                <button style={{backgroundColor:"green",color:"white",borderRadius:"10px"}}>Add<MdOutlineAdd style={{fontSize:"15px"}}/>
                </button>
            </div>
            
            <div className="col-lg-12">
              <DataTable
                columns={columns}
                data={allData.filter((row) => {
                  if (selectedStage === "") return true;
                  return row.stage.toString() === selectedStage;
                })}
                pagination
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                responsive
                highlightOnHover
                striped
                noDataComponent={<div>No data available</div>}
              />
            </div>
          </div>
        </div>
  )
}

export default Assets
