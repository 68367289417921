import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTable } from "react-table";


const JobRegistered = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.theleadsense.com/api/v1/student-data")
      .then((response) => {
        if (response.data.status === 200) {
          setData(response.data.data.users_details);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const columns = React.useMemo(
    () => [
      { Header: "ID", accessor: "id" },
      { Header: "Name", accessor: "name" },
      { Header: "Email", accessor: "email" },
      { Header: "Phone", accessor: "phone" },
      { Header: "Program", accessor: "program" },
      { Header: "Cohort", accessor: "cohort" },
      { Header: "Experience (Years)", accessor: "total_work_experience" },
      { Header: "Current CTC", accessor: "current_ctc" },
      { Header: "Expected CTC", accessor: "expected_ctc" },
      {
        Header: "CV",
        accessor: "cv_url",
        Cell: ({ value }) => (
          <button>
            <a href={value} download>
              Download CV
            </a>
          </button>
        ),
      },
      {
        Header: "Video",
        accessor: "video_url",
        Cell: ({ value }) => (
          <button>
            <a href={value} download>
              Download Video
            </a>
          </button>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Job Registered Users</h2>
      <table {...getTableProps()} className="min-w-full border border-gray-300">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="bg-gray-200">
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className="border p-2 text-left"
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="border">
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} className="p-2 border">
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default JobRegistered;