import React, { useState } from "react";
import * as XLSX from "xlsx";
import Select from "react-select";
import { useDropzone } from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function LeadUpload() {
  const [file, setFile] = useState(null);
  console.log(file?.name)
  const [campaignName, setCampaignName] = useState(null);
  const [source, setSource] = useState(null);
  console.log(file)

  // The provided campaign data string
  const campaignDataString = '[{"NAME":"LeadSense","VALUE":"683"},{"NAME":"RMA","VALUE":"687"},{"NAME":"Publander","VALUE":"1637"},{"NAME":"CPX","VALUE":"685"},{"NAME":"LIBA","VALUE":"691"},{"NAME":"LIBA - PGDM","VALUE":"1677"},{"NAME":"LIBA - HCM","VALUE":"1657"},{"NAME":"LIBA - GGU","VALUE":"1659"},{"NAME":"LIBA-DBA GGU","VALUE":"2399"},{"NAME":"Management","VALUE":"689"},{"NAME":"HR","VALUE":"919"},{"NAME":"JAIN +","VALUE":"1621"},{"NAME":"Manipal","VALUE":"1775"},{"NAME":"UpGrad- Bharathidasan","VALUE":"1777"},{"NAME":"UpGrad- Andhra Uni","VALUE":"1835"},{"NAME":"Upgrad- Study Abroad","VALUE":"1837"},{"NAME":"Datamining GGU","VALUE":"1855"},{"NAME":"UpGrad- WP","VALUE":"2051"},{"NAME":"UpGrad- SA","VALUE":"2053"},{"NAME":"LIBA PGDM Regular (FN/OCI/GCC)","VALUE":"2101"},{"NAME":"123Admissions","VALUE":"2111"},{"NAME":"D Y Patil Mumbai","VALUE":"2121"},{"NAME":"MGR","VALUE":"2123"},{"NAME":"KLU","VALUE":"2125"},{"NAME":"Chandigarh University","VALUE":"2127"},{"NAME":"LIBA - PGPM","VALUE":"2197"},{"NAME":"GGU-Andhra","VALUE":"2235"},{"NAME":"LIBA - ADR","VALUE":"2281"},{"NAME":"iiit - Bangalore","VALUE":"2301"},{"NAME":"KUK","VALUE":"2311"},{"NAME":"LIBA - Regular","VALUE":"2321"},{"NAME":"Vignan University","VALUE":"2331"},{"NAME":"Vivekananda University","VALUE":"2333"},{"NAME":"UpGrad - DBA","VALUE":"2351"},{"NAME":"UpGrad - Liverpool","VALUE":"2361"},{"NAME":"PHD","VALUE":"2371"},{"NAME":"OP Jindal Law","VALUE":"2389"}]';

  const campaignDataArray = JSON.parse(campaignDataString);
  const campaignOptions = campaignDataArray.map(option => ({
    value: option.VALUE,
    label: option.NAME,
  }));

  const sourceArray = [
    { "NAME": "LinkedIn", "VALUE": "UC_DO06YJ" },
    { "NAME": "META", "VALUE": "STORE" },
    { "NAME": "GADS", "VALUE": "UC_RW6U9D" },
    { "NAME": "Push", "VALUE": "EMAIL" },
    { "NAME": "Web", "VALUE": "WEB" },
    { "NAME": "Monster", "VALUE": "CALL" },
    { "NAME": "Referral", "VALUE": "WEBFORM" },
    { "NAME": "Naukri", "VALUE": "CALLBACK" },
    { "NAME": "Sales boost", "VALUE": "RC_GENERATOR" },
    { "NAME": "Other", "VALUE": "OTHER" },
    { "NAME": "Facebook - Open Channel", "VALUE": "1 | FACEBOOK" },
    { "NAME": "Live chat - Open Channel", "VALUE": "1 | OPENLINE" },
    { "NAME": "Facebook: Comments - Open Channel", "VALUE": "1 | FACEBOOKCOMMENTS" },
    { "NAME": "Sulekha", "VALUE": "UC_4O5SZ1" },
    { "NAME": "Paper Insertion", "VALUE": "UC_P0XC3A" },
    { "NAME": "Outdoor activity", "VALUE": "UC_G3V45Y" },
    { "NAME": "Chat", "VALUE": "UC_Q2Q0CS" },
    { "NAME": "Webinar", "VALUE": "UC_ZY2AUN" },
    { "NAME": "WCC", "VALUE": "UC_VUH2SA" },
    { "NAME": "Collegedunia", "VALUE": "UC_N0LVVL" },
    { "NAME": "WhatsApp Campaign", "VALUE": "UC_OQT6G2" },
    { "NAME": "Big data", "VALUE": "UC_X9Y30S" },
    { "NAME": "Data Mining Thilaga", "VALUE": "UC_IOVJX1" },
    { "NAME": "Data Mining Tara Henry", "VALUE": "UC_XMA4JX" },
    { "NAME": "Data Mining Anu Radha", "VALUE": "UC_GD1YQ1" },
    { "NAME": "Data Mining Niranjan", "VALUE": "UC_77808R" },
    { "NAME": "Data Mining Saravana", "VALUE": "UC_5MSFGB" },
    { "NAME": "Data Mining Iniyazh", "VALUE": "UC_FK6TXV" },
    { "NAME": "Walk In", "VALUE": "UC_0IHWYK" },
    { "NAME": "Practo", "VALUE": "UC_ZINCQM" },
    { "NAME": "Taboola", "VALUE": "UC_M6FZWA" },
    { "NAME": "LinkedIn ADs", "VALUE": "UC_0DAZE2" }
  ];

  const sourceOptions = sourceArray.map(option => ({
    value: option.VALUE,
    label: option.NAME,
  }));

  // Handle file input change
  const handleDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      console.log('kkkkkkkkkkkkkkkkkkkkkkkkk')
      setFile(file);
    } else {
      toast.error("Please upload a valid Excel file.");
    }
  };

  // Handle file submission
  const handleFileSubmit = () => {
    console.log('kkkkkkkkkkkkkkkkkkkkkkkkk')
    const authToken = sessionStorage.getItem("Token"); // Get the authToken from sessionStorage

    if (authToken) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet);
          console.log(jsonData)

          toast.info("Processing your data...");

          // Sending data to API
          jsonData.forEach((row) => {
            const raw = JSON.stringify({
              name: row.Name,
              email: row.Email,
              phone: row.Phone,
              course: row.Course,
              campaignName: campaignName.value,
              Source: source.value,
            });

            const myHeaders = new Headers();
            myHeaders.append("authToken", authToken); // Use the authToken from sessionStorage
            myHeaders.append("Content-Type", "application/json");

            const requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: raw,
              redirect: "follow",
            };

            fetch("https://api.theleadsense.com/api/v1/internal/lead/add", requestOptions)
              .then((response) => response.text())
              .then(() => {
                toast.success("Data uploaded successfully!");
              })
              .catch(() => {
                toast.error("Error occurred while uploading data!");
              });
          });
        };
        reader.readAsBinaryString(file);
      } else {
        toast.error("Please upload a file.");
      }
    } else {
      toast.error("No authToken found. Please log in again.");
    }
  };

  // Dropzone settings
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: ".xlsx,.xls"
  });
  const handleDownloadTemplate = () => {
    const sampleData = [
      { Name: "John Doe", Email: "john@example.com", Phone: "1234567890", Course: "MBA" },
      { Name: "Jane Smith", Email: "jane@example.com", Phone: "0987654321", Course: "BBA" }
    ];

    const worksheet = XLSX.utils.json_to_sheet(sampleData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Leads Template");

    XLSX.writeFile(workbook, "Lead_Template.xlsx");
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <h1>Upload Lead</h1>
      </div>
      <div className="col-lg-12">
        <div className="card" style={{ padding: "30px" }}>
          <div className="row">
            <div className="col-lg-12">
              <div className="mb-5" style={{float:"right"}}>
                <button onClick={() => handleDownloadTemplate()}>Download Template</button>
              </div>
            </div>
            <div className="col-lg-12">
              <div
                {...getRootProps()}
                style={{
                  border: "2px dashed #ccc",
                  padding: "20px",
                  textAlign: "center",
                  cursor: "pointer"
                }}
              >
                <input {...getInputProps()} />
                <p>Drag & Drop your Excel file here, or click to select</p>
                <h5>{file?.name}</h5>
              </div>
            </div>
            <div className="col-lg-4">
              <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                <label>Select Campaign</label>
                <Select
                  options={campaignOptions}
                  value={campaignName}
                  onChange={setCampaignName}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                <label>Select Source</label>
                <Select
                  options={sourceOptions}
                  value={source}
                  onChange={setSource}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <button
                onClick={handleFileSubmit}
                style={{ marginTop: "35px", marginBottom: "20px" }}
              >
                Upload and Send to API
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Toast container to display the messages */}
      <ToastContainer />
    </div>
  );
}

export default LeadUpload;
