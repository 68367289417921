import React from "react";
import { useState } from "react";
import { GrView } from "react-icons/gr";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { MdOutlineAdd } from "react-icons/md";


function Employee() {
  const [selectedStage, setSelectedStage] = useState("");
  const navigate = useNavigate();

  const employeeDetails = [
    {
      EmpName: "Karthik",
      Role: "Digital Marketing",
      Phone: "9145256452",
    },
    {
      EmpName: "Veronica",
      Role: "HR",
      Phone: "9145256452",
    },
    {
      EmpName: "Ram Kumar",
      Role: "Developer",
      Phone: "9145256452",
    },
    {
      EmpName: "Uma",
      Role: "Digital Marketing",
      Phone: "9145256452",
    },
    {
      EmpName: "Durga",
      Role: "BPO",
      Phone: "9145256452",
    },
    {
      EmpName: "Bala",
      Role: "UI/UX Designer",
      Phone: "9145256452",
    },
    {
      EmpName: "Logesh",
      Role: "Data Analyst",
      Phone: "9145256452",
    },
  ];

  const handleViewClick = (employee) => {
    navigate(`/empdetails/${employee}`);
  };


  const allData=employeeDetails.map((data,index)=>({
    employeeName:data.EmpName || "N/A",
    employeeRole:data.Role || "N/A",
    contactNo:data.Phone || "N/A",
    view: <GrView onClick={() => handleViewClick(data.EmpName)} style={{cursor:"pointer"}}/>,
    edit:<FaEdit />,
        delete:<MdDelete style={{fontSize:"20px",color:'red',cursor:"pointer"}}/>,
        stage: index,
  }))

  const columns =[
    {
        name:"S.No",
        selector:(row,index)=>index+1,
        sortable:true,
    },
    {
        name:"Name",
        selector:(row,index)=>row.employeeName,
        sortable:true,
    },
    {
        name:"Role",
        selector:(row,index)=>row.employeeRole,
        sortable:true,
    },
    {
        name:"Contact No",
        selector:(row,index)=>row.contactNo,
        sortable:true,
    },
    {
        name:"View",
        selector:(row,index)=>row.view,
        sortable:true,
    },
   {
    name:"Edit",
    selector:(row,index)=>row.edit,
    sortable:true,
   },
   {
    name:"Delete",
    selector:(row,index)=>row.delete,
    sortable:true,
   }
  ]
  return(
    <div className="department">
    <div className="row">
      <div className="col-lg-10 mt-3 mb-3">
        <h3>Employee Details</h3>
      </div>
          <div className="col-lg-2 mt-3">
                  <button style={{backgroundColor:"green",color:"white",borderRadius:"10px"}}>Add<MdOutlineAdd style={{fontSize:"15px"}}/>
                  </button>
              </div>
      {/* <div className="col-lg-6 mb-3">
        <input
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search by name or phone"
          className="form-control mb-2"
        />
      </div> */}
      {/* <div className="col-lg-6 mb-3">
        <select
          value={selectedStage}
          onChange={(e) => setSelectedStage(e.target.value)}
          className="form-control"
        >
          <option value="">Job Role</option>
          <option value="0">HR</option>
          <option value="1">Digital Marketing</option>
          <option value="2">Developer</option>
          <option value="3">UI/UX Designer</option>
          <option value="4">Data Analyst</option>
          <option value="5">BPO</option>
          <option value="6">Interns</option>
        </select>
      </div> */}
      <div className="col-lg-12">
        <DataTable
          columns={columns}
          data={allData.filter((row) => {
            if (selectedStage === "") return true;
            return row.stage.toString() === selectedStage;
          })}
          pagination
          paginationPerPage={5}
          paginationRowsPerPageOptions={[5, 10, 15, 20]}
          responsive
          highlightOnHover
          striped
          noDataComponent={<div>No data available</div>}
        />
      </div>
    </div>
  </div>
  )
}

export default Employee;
