import React from 'react';
import Dashboard from '../../pages/Dashboard/Dashboard';
import Courses from "../../pages/Courses/CoursesView";
import Universitys from '../../pages/University/Universitys';
import Reports from '../../pages/Reports/Resports';
import Payment from '../../pages/Payment/Payment'
import Settings from '../../pages/Settings/Settings';
import Logout from '../../pages/Logout/Logout';
import Leads from '../../pages/Leads/Leads';
import LeadUplaod from '../../pages/LeadUpload/LeadUpload';
import Department from '../../pages/Department/Department';
import Employee from '../../pages/Employee/Employee';
import DepDetails from '../../pages/Department/DepDetails';
import EmpDetails from "../../pages/Employee/EmpDetails"
import Assets from '../../pages/Assets/Assets';
import ResgisterStudents from '../../pages/RegisterStudents/RegisterStudents';
import Universities from '../../pages/Universities/Universities';
import Bharathidasan_University from '../../pages/University_Courses/Bharathidasan_University';
import Andhra_University from '../../pages/University_Courses/Andhra_University';
import Centurion_University from '../../pages/University_Courses/Centurion_University';
import Chandigarh_University from '../../pages/University_Courses/Chandigarh_University';
import D_Y_PATIL_University_Mumbai from '../../pages/University_Courses/D_Y_PATIL_University_Mumbai';
import D_Y_PATIL_University_Pune from '../../pages/University_Courses/D_Y_PATIL_University_Pune';
import D_Y_PATIL_University from '../../pages/University_Courses/D_Y_PATIL_University';
import Dr_MGR_University from '../../pages/University_Courses/Dr_MGR_University';
import Jain_University from '../../pages/University_Courses/Jain_University';
import KIIT_University from '../../pages/University_Courses/KIIT_University';
import JobRegistered from '../../pages/Job-Register/Job_reg';

const routes = [
  { path: "/", name: "dashboard", element: Dashboard, exact: true },
  { path: "/courses", name: "courses", element: Courses, exact: true },
  { path: "/university", name: "University", element:Universities, exact: true },
  { path: "/departments", name: "Department", element:Department, exact: true },
  { path: "/depdetails/:department", name: "Departmentdetails", element:DepDetails, exact: true },
  { path: "/employee", name: "Employee", element:Employee, exact: true },
  { path: "/empdetails/:employee", name: "Employee", element:EmpDetails, exact: true },
  { path: "/assets", name: "Assets", element:Assets, exact: true },
  { path: "/register-student", name: "RegisterStudent", element:ResgisterStudents, exact: true },
  { path: "/reports", name: "reports", element: Reports, exact: true },
  { path: "/payments", name: "payments", element: Payment, exact: true },
  { path: "/settings", name: "payments", element: Settings, exact: true },
  { path: "/logout", name: "payments", element: Logout, exact: true },
  { path: "/leads", name: "payments", element: Leads, exact: true },
  { path: "/internal", name: "payments", element: LeadUplaod, exact: true },


  //Universities
  { path: "/university/Andhra_University", name: "Andhra University", element:Andhra_University, exact: true },
  { path: "/university/Bharathidasan_University", name: "Bharathidasan University", element:Bharathidasan_University, exact: true },
  { path: "/university/Centurion_University", name: "Centurion University", element:Centurion_University, exact: true },
  { path: "/university/Chandigarh_University", name: "Chandigarh University", element:Chandigarh_University, exact: true },
  { path: "/university/D_Y_PATIL_University_Mumbai", name: "D_Y_PATIL_University_Mumbai", element:D_Y_PATIL_University_Mumbai, exact: true },
  { path: "/university/D_Y_PATIL_University_Pune", name: "D_Y_PATIL_University_Pune", element:D_Y_PATIL_University_Pune, exact: true },
  { path: "/university/D_Y_PATIL_University", name: "D_Y_PATIL_University", element:D_Y_PATIL_University, exact: true },
  { path: "/university/Dr_MGR_University", name: "Dr_MGR_University", element:Dr_MGR_University, exact: true },
  { path: "/university/Jain_University", name: "Jain_University", element:Jain_University, exact: true },
  { path: "/university/KIIT_University", name: "KIIT_University", element:KIIT_University, exact: true },
  { path: "/jobregister", name: "KIIT_University", element:JobRegistered, exact: true },
];

export default routes;
